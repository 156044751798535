import React, { useState, useEffect } from 'react';
import { core, internationalAddressAutocomplete, usAutocompletePro } from 'smartystreets-javascript-sdk';

function AddressAutocompleteForm({ country }) {
  const [client, setClient] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [autocomplete, setAutocomplete] = useState(false);
  const [streetLineEmpty, setStreetLineEmpty] = useState(null);
  const [error, setError] = useState(false);

  const isInternationalLookup = country !== 'US';
  const address1Selector = 'redemption_address_form_address_1';
  const address2Selector = 'redemption_address_form_address_2';
  const citySelector = 'redemption_address_form_city';
  const stateSelector = 'redemption_address_form_state';
  const zipSelector = 'redemption_address_form_zip';

  useEffect(() => {
    const key = gon.smarty_client_key;
    const credentials = new core.SharedCredentials(key);
    const license = isInternationalLookup ? "international-autocomplete-v2-cloud" : "us-autocomplete-pro-cloud";

    const clientBuilder = new core.ClientBuilder(credentials).withLicenses([license]);
    const client = isInternationalLookup ? clientBuilder.buildInternationalAddressAutocompleteClient() : clientBuilder.buildUsAutocompleteProClient();

    document.getElementById(address2Selector).value = "";
    document.getElementById(citySelector).value = "";
    document.getElementById(stateSelector).value = "";
    document.getElementById(zipSelector).value = "";

    setAutocomplete(gon.address_autocomplete);
    setClient(client);
    setSearchString("");
  }, [country]);


  useEffect(() => {
    const fetchAddresses = async () => {
      const lookup = isInternationalLookup ? new internationalAddressAutocomplete.Lookup() : new usAutocompletePro.Lookup();
      if (isInternationalLookup) {
        lookup.country = country;
      }
      lookup.search = searchString;

      client.send(lookup)
        .then(response => {
          let suggestions = response.result
            .filter(suggestion => suggestion?.entries <= 1)
            .map(suggestion => {
              if (isInternationalLookup) {
                return {suggestion, formatted: suggestion.addressText, id: suggestion.addressId};
              }
              return {suggestion, formatted: formattedSuggestion(suggestion)};
            });
          setSuggestions(suggestions);
        })
        .catch(error => {
          setError(true);
          setAutocomplete(false);
          console.error(error);
        });
    };

    if (shouldSearch && autocomplete && searchString !== '') {
      fetchAddresses();
    } else {
      setSuggestions([]);
    }

    if (searchString !== '') {
      setStreetLineEmpty(null);
    }
  }, [searchString]);

  const handleInputChange = (event) => {
    setShouldSearch(true);
    setSearchString(event.target.value);
    document.getElementById(address1Selector).value = event.target.value;
  };

  const handleAddressClick = (address) => {
    if (!autocomplete) { return; }

    setShouldSearch(false);

    if (isInternationalLookup) {
      const lookup = new internationalAddressAutocomplete.Lookup();
      lookup.country = country;
      lookup.addressId = address.id;

      client.send(lookup)
        .then(response => {
          const result = response.result[0];
          document.getElementById(address1Selector).value = result?.street || '';
          document.getElementById(citySelector).value = result?.locality || '';
          document.getElementById(stateSelector).value = result?.administrativeArea || '';
          document.getElementById(zipSelector).value = result?.postalCode || '';

          setSearchString(result?.street);
          setSuggestions([]);
        })
        .catch(error => {
          setError(true);
          setAutocomplete(false);
          console.error(error);
        });
    } else {
      setSearchString(address?.suggestion?.streetLine);

      document.getElementById(address1Selector).value = address?.suggestion?.streetLine;
      document.getElementById(address2Selector).value = address?.suggestion?.secondary;
      document.getElementById(citySelector).value = address?.suggestion?.city;
      document.getElementById(stateSelector).value = address?.suggestion?.state;
      document.getElementById(zipSelector).value = address?.suggestion?.zipcode;

      setSuggestions([]);
    }
  };

  const formattedSuggestion = (suggestion) => {
    const addressText = suggestion?.addressText ? `${suggestion.addressText} ` : "";
    const street = suggestion.streetLine ? `${suggestion.streetLine} ` : "";
    const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : "";
    const city = suggestion?.city ? `${suggestion.city} ` : "";
    const state = suggestion?.state ? `${suggestion.state}, ` : "";
    const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : "";

    return addressText + street + secondary + city + state + zip;
  };

  useEffect(() => {
    const form = document.querySelector('form'); // Replace 'form' with your form's selector if necessary
    const handleFormSubmit = (event) => {
      const addressValue = document.getElementById('address_1_freeform').value;
      if (addressValue.trim() === '') {
        event.preventDefault();
        setStreetLineEmpty(gon.address_1_empty);
      } else {
        setStreetLineEmpty(null);
      }
    };
  
    form.addEventListener('submit', handleFormSubmit);
  
    return () => {
      form.removeEventListener('submit', handleFormSubmit);
    };
  }, []);

  return(
    <div className="position-relative">
      {error &&
        <p className="alert alert-warning">{gon.error_message}</p>}
      {streetLineEmpty &&
        <p className="alert alert-danger mt-1 mb-1">{streetLineEmpty}</p>}
      <input
        id="address_1_freeform"
        type="text"
        value={searchString}
        onChange={handleInputChange}
        onBlur={() => {
          setTimeout(() => {
            const list = document.querySelector('.list-group');
            list.classList.add('d-none');
          }, 200);
        }}
        onFocus={() => {
          const list = document.querySelector('.list-group');
          list.classList.remove('d-none');
        }}
        placeholder={gon.address_line}
        className="form-control mb-1"
      />
      <ul
        className="list-group position-absolute start-0">
        {suggestions.map((suggestion) => (
          <li key={suggestion.formatted}
              style={{ cursor: 'pointer' }}
              className="list-group-item"
              onClick={() => handleAddressClick(suggestion)}>
            {suggestion.formatted}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AddressAutocompleteForm;
